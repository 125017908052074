<template>
  <div id="whatsapp" class="whatsapp" @click="sendMessageByWhatsapp">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_8332_41239)">
        <path
          d="M17.7589 13.7914C17.7121 13.769 15.9624 12.9074 15.6515 12.7955C15.5246 12.7499 15.3886 12.7054 15.244 12.7054C15.0077 12.7054 14.8093 12.8232 14.6547 13.0544C14.4799 13.3142 13.9508 13.9327 13.7873 14.1174C13.766 14.1418 13.7368 14.171 13.7194 14.171C13.7037 14.171 13.4329 14.0595 13.351 14.0239C11.4741 13.2086 10.0494 11.248 9.85405 10.9173C9.82616 10.8698 9.82499 10.8482 9.82476 10.8482C9.83161 10.8231 9.89473 10.7598 9.9273 10.7271C10.0226 10.6328 10.1258 10.5086 10.2257 10.3883C10.273 10.3314 10.3204 10.2743 10.3669 10.2206C10.5119 10.0519 10.5764 9.92103 10.6512 9.76939L10.6904 9.69062C10.873 9.32773 10.717 9.0215 10.6666 8.92262C10.6252 8.83986 9.88647 7.05685 9.80792 6.86952C9.61904 6.4175 9.36946 6.20703 9.02263 6.20703C8.99044 6.20703 9.02263 6.20703 8.88766 6.21272C8.72333 6.21965 7.82839 6.33747 7.4327 6.5869C7.01309 6.85145 6.30322 7.69472 6.30322 9.17773C6.30322 10.5125 7.15024 11.7727 7.5139 12.252C7.52294 12.2641 7.53953 12.2886 7.56361 12.3238C8.95631 14.3578 10.6925 15.8651 12.4525 16.5682C14.1469 17.245 14.9493 17.3232 15.4055 17.3232H15.4055C15.5972 17.3232 15.7507 17.3082 15.886 17.2949L15.9719 17.2867C16.5572 17.2348 17.8436 16.5683 18.1362 15.7551C18.3667 15.1147 18.4274 14.415 18.2741 14.161C18.169 13.9883 17.988 13.9014 17.7589 13.7914Z"
          fill="white" />
        <path
          d="M12.213 0C5.71307 0 0.424969 5.24836 0.424969 11.6995C0.424969 13.786 0.983358 15.8284 2.04115 17.6159L0.0165014 23.5883C-0.0212129 23.6996 0.00683904 23.8227 0.0892027 23.9066C0.148657 23.9673 0.229307 24 0.31167 24C0.343229 24 0.375021 23.9952 0.406034 23.9854L6.63357 22.0064C8.33772 22.917 10.2638 23.3976 12.2131 23.3976C18.7124 23.3977 24 18.1498 24 11.6995C24 5.24836 18.7124 0 12.213 0ZM12.213 20.9606C10.3788 20.9606 8.60227 20.4309 7.07515 19.4289C7.0238 19.3951 6.96419 19.3778 6.90419 19.3778C6.87247 19.3778 6.84068 19.3826 6.80975 19.3925L3.69014 20.3841L4.6972 17.413C4.72977 17.3169 4.71349 17.2108 4.65349 17.1288C3.49058 15.5398 2.87585 13.6625 2.87585 11.6995C2.87585 6.59221 7.06448 2.43709 12.2129 2.43709C17.3608 2.43709 21.5489 6.59221 21.5489 11.6995C21.549 16.8061 17.3609 20.9606 12.213 20.9606Z"
          fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_8332_41239">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<style scoped lang="scss">
.whatsapp {
  border: none;
  border-radius: 50%;
  padding: 8px;
  background: #25d366;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  gap: 10px;
  flex-shrink: 0;
  position: fixed;
  bottom: 60px;
  right: 20px;
  transition: transform 0.2s;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    background: #4acf7b;
    transform: scale(1.25);
  }
}

@media screen and (max-width: 500px) {
  .whatsapp {
    border-radius: 50px;
    width: 65px;
    height: 65px;
    bottom: 20px;
    right: 20px;
  }
}

.bottom {
  transition: all 0.5s ease;
  bottom: 10px !important;
}
</style>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      getSettings: "config/getSettings",
    }),
  },
  methods: {
    sendMessageByWhatsapp() {
      const number = this.getSettings["whatsapp_number"].replace(/\+/g, "");
      const message = this.getSettings["whatsapp_message"];

      const whatstext = `https://api.whatsapp.com/send?phone=${number}${message ? `&text=${message}` : ""
        }`;

      window.open(whatstext, "_blank");
    },
    handleScroll() {
      if (document.querySelector(".layout-page__overflow").scrollTop > 30) {
        document.querySelector("#whatsapp").classList.remove("bottom");
      } else {
        document.querySelector("#whatsapp").classList.add("bottom");
      }
    },
  },
  data() {
    return {
      elementoVisivel: false,
    };
  },
  mounted() {
    document
      .querySelector(".layout-page__overflow")
      .addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    let element = document.querySelector(".layout-page__overflow");
    if (element) {
      element.removeEventListener("scroll", this.handleScroll);
    }

  },
};
</script>
